.outer-container {
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08) !important;
  position: relative;
  top: 0;
  z-index: 100;
  transition: all 0.3s ease;
}

.outer-container.sticky {
  position: fixed;
  top: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 1000;
}

.inner-container {
  max-width: 1140px;
  margin: 0 auto;
}

.header1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px;
  position: relative;
}

.logo {
  font-size: 20px;
  font-weight: bold;
}

.logo img {
  max-width: 300px !important;
  max-height: 75px !important;
  object-fit: cover;
  margin-left: 10px !important;
}

.nav-menu {
  display: flex;
}

.nav-menu ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  padding: 0;
}

.nav-menu ul li {
  margin-right: 20px;
  position: relative;
  padding: 8px !important;
}

.nav-menu ul li:hover {
  color: black;
  border-radius: 30px;
  padding: 20px;
  font-weight: bold;
}

.nav-menu ul li a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
  font-size: 18px;
  padding: 8px;
}

.nav-menu ul li a:hover {
  background-color: rgba(173, 216, 230, 0.29);
  color: blue;
  border-radius: 10px;
  font-weight: bold;
}

.nav-menu .dropdown {
  position: relative;
}

.nav-menu .dropdown a {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dropdown-icon {
  font-size: 18px;
  color: #666;
}

.nav-menu .dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  min-width: 220px;
  width: 20%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1;
  visibility: visible;
}

.dropdown-content li {
  padding: 10px;
  text-align: left;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  height: 3px;
  width: 25px;
  background-color: #333;
  margin: 4px 0;
  transition: 0.4s;
}

/*_______________________________________________Responsive Code________________________________________*/

@media (max-width: 1140px) {
  .inner-container {
    width: 100%;
    padding: 0 0px;
  }

  .nav-menu ul {
    flex-wrap: wrap;
  }

  .nav-menu ul li {
    margin-right: 10px;
  }
}

@media (max-width: 900px) {
  .nav-menu {
    display: none;
    align-items: left;
    position: absolute;
    top: 100%;
    right: 0;
    width: 35%;
    background-color: #fff;
    flex-direction: column;
    left: auto;
    z-index: 999;
    height: 100vh;
  }

  .nav-menu.open {
    display: flex;
  }

  .nav-menu ul {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-menu ul li {
    margin: 10px 0;
  }

  .hamburger {
    display: flex;
    margin: 20px;
  }

  .dropdown-content {
    top: -20px;
    left: -235px;
    max-height: 350px;
    overflow-y: auto;
  }
}

@media (max-width: 980px) {
  .logo img {
    max-width: 200px !important;
    max-height: 60px !important;
    margin-left: 0 !important;
  }

  .nav-menu ul li a {
    font-size: 14px;
  }
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(10px, 5px);
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(10px, -5px);
}
