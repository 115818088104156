.TitleProps-container {
  max-width: 1140px;
  margin: auto;
  padding-top: 81px;
}

.TitleProps_section1 {
  position: relative;
  color: white;
  margin-top: -80px;
}

.TitleProps_section1-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.TitleProps_section1-content {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 50%;
  text-align: left;
  color: white;
}

.TitleProps_section1-content h1 {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
}

.TitleProps_section1-content p {
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  margin: 10px 0;
  line-height: 1.5;
  width: 500px;
  word-wrap: break-word;
}

.TitleProps_section1-content button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.TitleProps_section1-content button:hover {
  background-color: #0056b3;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/*_____________________________________________Responsive design___________________________________________*/

@media screen and (max-width: 1200px) {
  .TitleProps-container {
    padding-top: 81px;
  }

  .TitleProps_section1-content {
    left: 40%;
    width: 60%;
  }

  .TitleProps_section1-content h1 {
    font-size: 2rem;
  }

  .TitleProps_section1-content p {
    font-size: 1.2rem;
    width: 100%;
  }

  .TitleProps_section1-image {
    height: 350px;
  }
}
@media screen and (max-width: 980px) {
  .TitleProps-container {
    padding-top: 75px;
  }
}

@media screen and (max-width: 768px) {
  .TitleProps-container {
    padding-top: 89px;
  }
  .TitleProps_section1-content {
    top: 50%;
    left: 50%;
    width: 70%;
    margin-left: -30px;
  }

  .TitleProps_section1-content h1 {
    font-size: 1.5rem;
  }

  .TitleProps_section1-content p {
    font-size: 1rem;
    width: 100%;
  }

  .TitleProps_section1-image {
    height: 300px;
    margin-top: -15px;
  }

  .TitleProps_section1-content button {
    margin-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .TitleProps-container {
    padding-top: 72px;
  }
  .TitleProps_section1-content {
    top: 40%;
    left: 50%;
    width: 90%;
    margin-left: 10px;
  }

  .TitleProps_section1-content h1 {
    font-size: 1.5rem;
    margin-top: 50px;
  }

  .TitleProps_section1-content p {
    font-size: 1rem;
    margin-top: -10px;
    line-height: 1.3;
  }

  .TitleProps_section1-image {
    height: 250px;
  }

  .TitleProps_section1-content button {
    padding: 8px 15px;
    font-size: 0.9rem;
    margin-top: 10px;
  }
}

/*________________________________________________Use Case Props.css______________________________________*/

.small-card {
  max-width: 340px;
  border-radius: 8px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  margin: 10px;
  height: 500px;
  overflow: hidden;
}

.card-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: 15px;
  gap: 20px;
}

@media screen and (max-width: 768px) {
  .card-container {
    justify-content: center;
  }

  .small-card {
    width: 70%;
    margin: 10px 0;
  }
}

.use-case-heading {
  text-align: left;
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: black;
  padding: 8px;
  margin-left: 5px;
  font-family: "Roboto", sans-serif;
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-title {
  font-size: 18px;
  margin: 10px 0;
  color: black;
}

.card-description {
  font-size: 17px;
  color: black;
  padding: 0 10px 10px;
  line-height: 1.4;
  text-align: left;
}

.small-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.usecase-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

/*_____________________________________________Get in Touch Styles________________________________________*/

.getintouch-container {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.getintouch-image {
  width: 50%;
}

.getintouch-image img {
  width: 80%;
  height: auto;
  margin-left: -50px;
}

.getintouch-content {
  width: 50%;
  padding-left: 20px;
}

.getintouch-heading {
  font-size: 2rem;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  text-align: left;
}

.getintouch-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: left;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  text-align: justify;
}

.getintouch-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: -76%;
  margin-top: 20px;
  margin-left: -76%;
}

.getintouch-button:hover {
  background-color: #0056b3;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/*_____________________________________________Responsive Design__________________________________________*/

@media (max-width: 768px) {
  .getintouch-container {
    flex-direction: column;
    align-items: center;
  }

  .getintouch-image {
    margin-left: 40px;
  }

  .getintouch-image,
  .getintouch-content {
    width: 100%;
  }

  .getintouch-content {
    padding-left: 0;
    order: -1;
  }

  .getintouch-button {
    padding: 8px 15px;
    font-size: 0.9rem;
    margin-right: 35px;
    font-family: "Roboto", sans-serif;
    background-color: #007bff;
  }

  .getintouch-heading {
    font-size: 1.5rem !important;
  }

  .getintouch-description {
    font-size: 1rem !important;
  }

  .getintouch-button {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 578px) {
  .getintouch-button {
    margin-right: 15px;
  }
}

@media (max-width: 484px) {
  .getintouch-button {
    margin-right: -30px;
  }
}
