.about-us {
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
}

.about-us-image {
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
}

.about-us-image img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  display: block;
}

.about-us-text-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-text {
  max-width: 1140px;
  width: 100%;
  padding: 0 50px;
  color: white;
  text-align: left;
}

.about-us-text h2 {
  font-size: 2rem;
  line-height: 1.6;
  max-width: 400px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.about-us-content {
  max-width: 1140px;
  margin: auto;
  padding: 20px;
}

.about-us-content h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  text-align: left;
}

.about-us-content p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 10px;
  text-align: justify;
}

.about-us-next-section {
  background-color: #fafbff;
  width: 100%;
  padding: 20px 0;
}

.about-us-next-content {
  max-width: 1140px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  text-align: justify;
}

.text-section {
  flex: 1;
  padding-right: 40px;
}

.text-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: 0px;
}

.text-section p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.image-section {
  flex: 1;
  opacity: 0;
  animation: slideInFromRight 1s ease-in-out forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.image-section img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  border-radius: 10px;
}

@media (max-width: 899px) {
  .about-us-next-content {
    flex-direction: column;
    padding: 15px;
  }

  .image-section {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .about-us-next-content {
    flex-direction: column;
    padding: 15px;
  }

  .image-section {
    width: 100%;
  }

  .text-section {
    margin-right: 10px;
  }

  .image-section {
    margin-top: 20px;
  }

  .about-us-content h2 {
    font-size: 1.5rem;
  }

  .about-us-content p {
    font-size: 1rem;
  }

  .text-section h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    margin-top: -5px;
  }

  .text-section p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .about-us-text {
    padding-left: 20px;
  }
}

@media (max-width: 480px) {
  .text-section {
    padding: 0 2px;
  }
}
