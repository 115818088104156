.PlmSolution-background-container {
  width: 100vw;
  background-color: rgb(246, 248, 252);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.PlmSolution_section2 {
  max-width: 1140px;
  margin: 0 auto;
  text-align: left;
  color: black;
  padding: 20px;
}

.PlmSolution_section2 h2 {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
}

.PlmSolution_section2 p {
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  margin: 10px 0;
  line-height: 1.7;
  text-align: justify;
}

@media (max-width: 768px) {
  .PlmSolution_section2 p {
    font-size: 1rem;
  }

  .PlmSolution_section2 h2 {
    font-size: 1.5rem;
  }
}

.plm-layout {
  width: 100%;
}

.plm-logos {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.plm-logo {
  width: 200px;
  height: auto;
  border-radius: 8px;
  margin-left: 55px;
}

.plm-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.plm-main-image {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  /* Tablet */
  .plm-logos {
    justify-content: center;
  }

  .plm-logo {
    width: 135px;
  }

  .plm-main-image {
    width: 90%;
  }
}

@media (max-width: 766px) {
  /* Mobile */
  .plm-logos {
    flex-wrap: wrap;
    justify-content: center;
  }

  .plm-logo {
    width: 150px;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .plm-main-image {
    width: 100%;
  }
}
