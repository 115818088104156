/*__________________CAD Automation and customization____________________*/
.cad-split-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding-bottom: 30px;
}

.aiml-split-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding-bottom: 30px;
}

.CV-split-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fafbff;
  padding-bottom: 30px;
}

.Digital-split-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fafbff;
  padding-bottom: 30px;
}

.Fullstack-split-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding-bottom: 30px;
}

.PLM-split-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding-bottom: 30px;
}

.cad-split-screen {
  display: flex;
  width: 1140px;
  flex-wrap: wrap;
  opacity: 1;
  /* transform: translateY(80px);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out; */
}

/* .cad-split-screen.fade-in {
  opacity: 1;
  transform: translateY(0);
} */

.cad-content,
.automotive-cad-content {
  text-align: justify;
}

/* Left side (content) */
.cad-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: left;
}

.cad-left h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.cad-left p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.cad-left ul {
  list-style-type: disc;
  padding-left: 20px;
}

.cad-left li {
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
}

/* Right side (image) */
.cad-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aiml-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.cad-right img {
  max-width: 100%;
  max-height: 80%;
  object-fit: cover;
  border-radius: 20px;
}

.aiml-right img {
  max-width: 100%;
  max-height: 80%;
  object-fit: cover;
  border-radius: 20px;
}

/* Styling for the button */
.cad-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  text-decoration: none;
}

.cad-button:hover {
  background-color: #0056b3;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.cad-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/* ________________________________________Automotive CAD_____________________________________ */
.automotive-cad-split-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fafbff;
  padding-bottom: 30px;
}

/* Split screen for Automotive CAD */
.automotive-cad-split-screen {
  display: flex;
  width: 1140px;
  flex-wrap: wrap;
  opacity: 1;
  /* transform: translateY(80%);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out; */
}

/* .automotive-cad-split-screen.fade-slide-in {
  opacity: 1;
  transform: translateY(0);
} */

/* Left side (content) for Automotive CAD */
.automotive-cad-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: left;
}

.automotive-cad-left h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.automotive-cad-left p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.automotive-cad-left ul {
  list-style-type: disc;
  padding-left: 20px;
}

.automotive-cad-left li {
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
}

/* Button styling for Automotive CAD */
.automotive-cad-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  text-decoration: none;
}

.automotive-cad-button:hover {
  background-color: #0056b3;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.automotive-cad-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/* Right side (image) for Automotive CAD */
.automotive-cad-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.automotive-cad-right img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.cv-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cv-right img {
  max-width: 100%;
  height: 70%;
  object-fit: cover;
  border-radius: 20px;
}

.plm-solution {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.plm-solution img {
  max-width: 100%;
  max-height: 80%;
  object-fit: cover;
  border-radius: 20px;
}

@media (max-width: 1220px) {
  .cv-right img {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .cad-split-outer {
    padding-bottom: 60px;
  }

  .automotive-cad-split-outer {
    padding-bottom: 60px;
  }

  .aiml-split-outer {
    padding-bottom: 100px;
  }

  .cad-split-screen {
    flex-direction: column;
  }

  .automotive-cad-split-screen {
    flex-direction: column;
  }

  .cad-left h1,
  .automotive-cad-left h1 {
    font-size: 1.5rem;
  }

  .cad-left p,
  .automotive-cad-left p {
    font-size: 1rem !important;
  }

  .cad-left li {
    font-size: 1rem !important;
  }

  .cad-button,
  .automotive-cad-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .cad-right img,
  .automotive-cad-right img {
    max-width: 80%;
    max-height: 80%;
    margin-top: 30px;
  }

  .plm-solution {
    max-width: 60% !important;
    max-height: 60% !important;
    text-align: center;
    margin-left: 140px;
    margin-left: 170px;
  }

  .aiml-right img {
    max-width: 60%;
    max-height: 60%;
    margin-top: 80px;
  }

  .automotive-cad-left {
    order: -1;
  }

  .automotive-cad-left li {
    font-size: 1rem;
  }

  .cv-right img {
    width: 80%;
    height: 40%;
    margin: 120px 0;
  }

  .aiml-split-outer {
    height: auto;
  }

  .CV-split-outer {
    height: auto;
    padding-bottom: 20px;
  }

  .Digital-split-outer {
    height: auto;
    padding-bottom: 30px;
  }

  .Fullstack-split-outer {
    height: auto;
  }

  .PLM-split-outer {
    height: auto;
    padding-bottom: 20px;
  }
}

@media (max-width: 750px) {
  .aiml-right img {
    margin-top: 40px;
  }
  .aiml-split-outer {
    padding-bottom: 40px;
  }

  .cv-right img {
    margin: 60px 0;
  }
}

@media (max-width: 644px) {
  .plm-solution {
    margin-left: 100px;
  }
}
@media (max-width: 570px) {
  .cv-right img {
    margin: 30px 0;
  }
}

@media (max-width: 450px) {
  .plm-solution {
    margin-left: 60px;
  }
}

@media (max-width: 480px) {
  .cad-split-screen,
  .automotive-cad-split-screen {
    opacity: 1 !important;
    transform: translateY(0) !important;
    transition: none !important;
  }
}
