.careers-banner {
  font-family: "Roboto", sans-serif;
  margin-top: -20px;
}

.image-container {
  position: relative;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

.banner-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin-top: 20px;
}

.banner-text {
  max-width: 1140px;
  width: 100%;
  padding: 0 50px;
  color: white;
  text-align: left;
}

.banner-text h1 {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  line-height: 1.4;
  margin-bottom: 20px;
  max-width: 400px;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
}

.careers-text-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply-now {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-top: 10px;
}

.apply-now .arrow {
  font-size: 28px;
  margin-left: 5px;
}

.apply-now:hover {
  text-decoration: underline;
}

.career-section {
  padding: 0 5px;
  background-color: rgb(249, 251, 255);
}

.career-content {
  display: flex;
  max-width: 1140px;
  margin: 0 auto;
  align-items: center;
  padding: 20px;
}

.career-image {
  flex: 1;
  padding-left: 40px;
}

.career-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.career-text {
  flex: 1;
  text-align: justify;
}

.career-text h1 {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  margin-bottom: 20px;
}

.career-text p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

/* _________Modal__________ */

.career-modal-content {
  max-width: 550px;
  width: 80%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fafbff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.modal-close-btn {
  position: absolute !important;
  top: -16px;
  right: -15px;
}

.modal-close-btnX {
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  color: white;
  font-weight: bold;
  font-size: 16px !important;
}

.career-header {
  font-family: "Roboto", sans-serif;
  font-size: 2rem !important;
  font-weight: 700 !important;
  padding-left: 20px !important;
  padding-bottom: -10px !important;
  margin-top: -12px !important;
}

.modal-body {
  padding: 20px;
  padding-bottom: 0px;
  overflow-y: auto;
  flex: 1;
}

.modal-actions {
  position: sticky;
  bottom: 0;
  background-color: #fafbff;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
}

.resume-section {
  border: 1px solid #b3b1b1;
  padding: 10px;
  margin-top: 15px;
  border-radius: 4px;
}

.resume-header {
  margin-bottom: 20px !important;
  font-size: 17px !important;
}

.resume-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.upload-btn {
  margin-right: 10px;
  margin-bottom: 10px !important;
}

.upload-icon {
  margin-bottom: 3px;
}

.submitBtn {
  margin-left: 10px;
}

.resume-name {
  margin-left: 20px;
  color: black;
  margin-top: 10px !important;
  min-height: 40px !important;
}

.buttoniconcommon {
  font-size: 16px !important;
  margin-right: 3px;
  margin-top: -2px;
}

@media (max-width: 600px) {
  .career-modal-content {
    width: 85%;
    max-width: 420px;
    padding: 15px;
    top: 5%;
    transform: translate(-50%, 0);
    max-height: 85vh;
  }

  .modal-body {
    padding: 5px;
    padding-bottom: 0px;
    overflow-y: auto;
    max-height: calc(90vh - 90px);
  }
}

/*__________________________________________________Responsive Styles____________________________________________*/

@media (max-width: 1200px) {
  .careers-banner {
    margin-top: -10px;
  }
}

@media (max-width: 768px) {
  .careers-banner {
    margin-top: -20px;
  }

  .career-content {
    flex-direction: column;
  }

  .career-image {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .banner-text {
    padding-left: 20px;
  }

  .banner-text h1 {
    font-size: 1.5rem;
  }

  .career-text h1 {
    font-size: 1.5rem !important;
  }

  .career-text p {
    font-size: 1rem !important;
  }
}

@media (max-width: 400px) {
  .banner-text {
    margin-left: 10px;
    margin-top: 30px;
    margin-right: 10px;
  }

  .apply-now {
    margin-top: -20px;
  }
}

/*________________________________________________Services Card_________________________________________________*/

.course-section {
  max-width: 1140px;
  margin: auto;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  position: relative;
}

.section-title {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  margin-top: -10px;
  color: black;
  text-align: center;
}

.course-cards-container {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.course-cards {
  display: flex;
}

.course-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 15px;
  margin: 10px;
  width: 280px;
  height: 280px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.course-card h3,
.course-card p {
  margin: 0;
}

.course-card h3 {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  color: black;
  margin-bottom: 10px;
  line-height: 1.4;
}

.course-card p {
  font-size: 17.5px;
  font-family: "Roboto", sans-serif;
  color: black;
  line-height: 1.5;
  text-align: justify;
}

.carousel-container {
  margin: 0 auto;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.apply-now-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  cursor: pointer;
  gap: 5px;
}

.career-apply-now-btn {
  color: white !important;
  background-color: #007bff !important;
  text-align: right;
}

.career-apply-now-btn:hover {
  background-color: #0056b3;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.career-apply-now-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.career-custom-arrow {
  background: transparent;
  border: none;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
}

.career-left-arrow {
  left: -3px;
}

.career-right-arrow {
  right: -12px;
  margin-top: -5px;
}

@media screen and (max-width: 1200px) {
  .careers-banner {
    margin-top: -20px;
  }
}

@media screen and (max-width: 1135px) {
  .course-card {
    width: 250px;
  }
}

@media screen and (max-width: 1098px) {
  .career-right-arrow {
    right: 165px;
  }
}

@media screen and (max-width: 1073px) {
  .career-right-arrow {
    right: 150px;
  }
}

@media screen and (max-width: 1040px) {
  .career-right-arrow {
    right: 130px;
  }
}

@media screen and (max-width: 1000px) {
  .career-right-arrow {
    right: 110px;
  }
}

@media screen and (max-width: 960px) {
  .career-right-arrow {
    right: 90px;
  }
}

@media screen and (max-width: 916px) {
  .career-right-arrow {
    right: 70px;
  }
}

@media screen and (max-width: 881px) {
  .career-right-arrow {
    right: 50px;
  }
}

@media screen and (max-width: 835px) {
  .career-right-arrow {
    right: 30px;
  }
}

@media screen and (max-width: 793px) {
  .career-right-arrow {
    right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .career-right-arrow {
    right: 0;
    left: 10px;
  }
}

@media screen and (max-width: 766px) {
  .course-cards-container {
    display: flex;
    justify-content: center;
  }

  .course-card {
    width: 300px;
    margin: 10px auto;
    font-size: 1rem !important;
  }

  .carousel-container {
    margin: 0 auto;
    padding-bottom: 30px;
    padding-left: 0;
  }

  .section-title {
    font-size: 1.5rem !important;
  }

  .course-card h3 {
    font-size: 1.5rem !important;
  }

  .course-card p {
    font-size: 1rem !important;
  }

  .career-custom-arrow {
    background: transparent;
    border: none;
    position: absolute;
    top: 38%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
  }

  .career-left-arrow {
    left: 80px;
  }

  .career-right-arrow {
    left: 500px;
  }
}

@media screen and (max-width: 696px) {
  .career-left-arrow {
    left: 80px;
  }

  .career-right-arrow {
    left: 420px;
  }

  .carousel-container {
    padding-left: 0;
  }
}

@media screen and (max-width: 640px) {
  .career-left-arrow {
    left: 60px;
  }

  .carousel-container {
    padding-left: 0;
  }
}

@media screen and (max-width: 580px) {
  .career-left-arrow {
    left: 40px;
  }

  .carousel-container {
    padding-left: 0;
  }
}

@media screen and (max-width: 525px) {
  .career-left-arrow {
    left: 30px;
  }

  .career-right-arrow {
    left: 440px;
  }

  .carousel-container {
    padding-left: 0;
  }
}

@media screen and (max-width: 506px) {
  .career-left-arrow {
    left: 15px;
  }

  .career-right-arrow {
    left: 380px;
  }

  .carousel-container {
    padding-left: 0;
  }
}

@media screen and (max-width: 488px) {
  .career-left-arrow {
    left: 10px;
  }
}

@media screen and (max-width: 450px) {
  .career-left-arrow {
    left: 30px;
  }

  .course-card {
    width: 230px;
    margin: 10px auto;
  }

  .career-right-arrow {
    left: 320px;
  }
}

@media screen and (max-width: 425px) {
  .career-left-arrow {
    left: 5px;
  }
}

@media screen and (max-width: 420px) {
  .course-cards-container {
    display: flex;
    justify-content: center;
  }

  .carousel-container {
    margin: 0 auto;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 390px) {
  .career-left-arrow {
    left: 20px;
  }

  .course-card {
    width: 200px;
  }

  .career-right-arrow {
    left: 280px;
  }
}

@media screen and (max-width: 370px) {
  .career-left-arrow {
    left: 5px;
  }
}

@media screen and (max-width: 360px) {
  .course-cards-container {
    display: flex;
    justify-content: center;
  }

  .course-card {
    width: 200px;
    margin: 10px auto;
  }

  .carousel-container {
    margin: 0 auto;
    padding-bottom: 30px;
  }

  .course-card p {
    font-size: 16.5px;
    line-height: 1.5;
    text-align: left;
  }
}
