.component-background-container1 {
  background-color: white;
}

.component-background-container2 {
  background-color: #fafbff;
}

.component-width-container {
  max-width: 1140px;
  margin: auto;
}

.component-main-container1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
}

.component-main-container2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
}

.component-image-container {
  overflow: hidden;
  border-radius: 5px;
}

.component-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
}

.component-image-container:hover .component-image {
  transform: scale(1.1);
}

.component-content-container {
  position: relative;
}

.component-content-container h1 {
  font-size: 28px;
  margin-bottom: 15px;
  text-align: justify;
}

.component-content-container p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

.component-button button {
  background-color: #4caf50;
  color: white;
  padding: 6px 12px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.component-button .MuiButton-startIcon,
.component-button .MuiButton-endIcon {
  margin: 0 4px;
  font-size: 16px;
}

.component-button button:hover {
  background-color: #388e3c;
  transform: scale(1.05);
}

.component-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
