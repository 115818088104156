.staffing-container {
  max-width: 1140px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.staffing-hero {
  position: relative;
  text-align: left;
  margin-bottom: 0;
  overflow: hidden;
  height: 400px;
  max-width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.staffing-hero-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  object-fit: cover;
}

.staffing-hero-content {
  position: relative;
  z-index: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
  margin-top: -50px;
}

.staffing-hero-title {
  font-size: 2rem;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  color: white;
  padding-left: 60px;
  margin-top: 150px;
}

.staffing-hero-description {
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  text-align: left;
  line-height: 1.6;
  padding-left: 60px;
  color: white;
  width: 450px;
  word-wrap: break-word;
  margin-top: 10px;
}

.staffing-cta-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 60px;
}

.staffing-cta-button:hover {
  background-color: #0056b3;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/* Description Section */
.staffing-description {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 0px;
  margin: 8px;
  gap: 15px;
}

.staffing-image-container {
  width: 50%;
}

.staffing-description img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 12px;
  margin-top: 30px;
}

.staffing-description-text {
  width: 50%;
  text-align: justify;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  margin-top: -15px;
}

/* Testimonials Section */
.staffing-testimonials {
  text-align: center;
  position: relative;
}

.staffing-testimonials-heading {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  position: absolute;
  top: -5;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  z-index: 2;
}

.testimonial-slider-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  background-image: url("./StaffingImages/bg.avif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 100vw;
  width: 100%;
  margin: 0;
  padding-top: 80px;
}

.testimonial-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  position: relative;
}

.testimonial-card {
  max-width: 600px;
  height: 200px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  scroll-snap-align: center;
  margin: 0 10px;
}

.testimonial-card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.testimonial-text {
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: justify;
}

.testimonial-person {
  font-weight: bold;
  text-align: left;
}

.testimonial-role {
  text-align: left;
  color: #777;
}

/* Navigation Icons */
.testimonial-nav {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
}

.testimonial-nav.left {
  margin-right: 550px;
}

.testimonial-nav.right {
  margin-left: 550px;
}

.testimonial-nav svg {
  font-size: 20px;
  color: #18244b;
  transition: transform 0.3s ease;
}

.testimonial-nav:hover svg {
  transform: scale(1.2);
}

.testimonial-slider::-webkit-scrollbar {
  display: none;
}

/*__________________________________________________For Hero Section_______________________________________________*/

@media (max-width: 1024px) {
  .staffing-container {
    margin-top: -68px;
  }

  .staffing-hero {
    height: 300px;
    margin-top: 70px;
    width: 100%;
  }

  .staffing-hero-title {
    font-size: 2rem;
    padding-left: 30px;
    margin-top: 75px;
  }

  .staffing-hero-description {
    font-size: 1.2rem;
    width: 100%;
    padding-left: 30px;
    width: 400px;
    word-break: break-word;
  }

  .staffing-cta-button {
    margin-left: 20px;
    display: flex;
  }
}

@media (max-width: 768px) {
  .staffing-container {
    margin-top: -70px;
  }

  .staffing-hero-content {
    margin-top: 50px;
    margin-left: -15px;
  }

  .staffing-hero-title {
    font-size: 1.5rem;
    margin-left: -10px;
  }

  .staffing-hero-description {
    font-size: 1rem;
    padding-left: 20px;
    width: 250px;
    word-break: break-word;
  }

  .staffing-cta-button {
    padding: 6px 8px;
    font-size: 0.9rem;
    margin-left: 20px;
  }
}

/*_____________________________________________For Description Section_____________________________________________*/

@media (max-width: 1120px) {
  .staffing-description-text {
    padding-right: 15px;
  }
}

@media (max-width: 1024px) {
  .staffing-description {
    flex-direction: column-reverse;
    gap: 20px;
    padding: 10px 10px;
  }

  .staffing-image-container {
    width: 100%;
  }

  .staffing-description img {
    margin-top: 8px;
  }

  .staffing-description-text {
    width: 100%;
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .staffing-description {
    flex-direction: column-reverse;
    gap: 15px;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .staffing-description img {
    height: 300px;
    margin-top: 8px;
  }

  .staffing-description-text {
    width: 100%;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .staffing-description {
    padding-top: 15px;
    padding-bottom: 5px;
    gap: 10px;
  }

  .staffing-description img {
    height: 250px;
  }

  .staffing-description-text {
    font-size: 0.95rem;
    line-height: 1.4;
  }
}

/*_______________________________________Responsive for Testimonials Section_______________________________________*/

@media (max-width: 1024px) {
  .testimonial-nav.left {
    margin-right: 550px;
  }

  .testimonial-nav.right {
    margin-left: 550px;
  }

  .testimonial-slider {
    max-width: 50%;
  }

  .testimonial-card {
    max-width: 80%;
  }

  .testimonial-nav {
    font-size: 24px;
    display: flex;
  }
}

@media (max-width: 808px) {
  .testimonial-nav.left {
    margin-right: 550px;
  }

  .testimonial-nav.right {
    margin-left: 550px;
  }

  .testimonial-slider {
    max-width: 50%;
  }

  .testimonial-card {
    max-width: 90%;
  }

  .testimonial-nav {
    font-size: 24px;
    display: flex;
  }
}
@media (max-width: 756px) {
  .testimonial-nav.left {
    margin-right: 550px;
  }

  .testimonial-nav.right {
    margin-left: 550px;
  }

  .testimonial-slider {
    max-width: 70%;
  }

  .testimonial-card {
    max-width: 70%;
  }

  .testimonial-nav {
    font-size: 24px;
    display: flex;
  }
}
@media (max-width: 656px) {
  .testimonial-nav.left {
    margin-right: 450px;
  }

  .testimonial-nav.right {
    margin-left: 450px;
  }

  .testimonial-slider {
    max-width: 70%;
  }

  .testimonial-card {
    max-width: 70%;
  }

  .testimonial-nav {
    font-size: 24px;
    display: flex;
  }
}
@media (max-width: 550px) {
  .testimonial-nav.left {
    margin-right: 405px;
  }

  .testimonial-nav.right {
    margin-left: 405px;
  }

  .testimonial-slider {
    max-width: 75%;
  }

  .testimonial-card {
    max-width: 75%;
  }

  .testimonial-nav {
    font-size: 24px;
    display: flex;
  }
}
@media (max-width: 450px) {
  .testimonial-nav.left {
    margin-right: 350px;
  }

  .testimonial-nav.right {
    margin-left: 350px;
  }

  .testimonial-slider {
    max-width: 80%;
  }

  .testimonial-card {
    max-width: 80%;
  }

  .testimonial-nav {
    font-size: 24px;
    display: flex;
  }
}
@media (max-width: 393px) {
  .testimonial-nav.left {
    margin-right: 300px;
  }

  .testimonial-nav.right {
    margin-left: 300px;
  }

  .testimonial-slider {
    max-width: 80%;
  }

  .testimonial-card {
    max-width: 80%;
  }

  .testimonial-nav {
    font-size: 24px;
    display: flex;
  }
}
@media (max-width: 350px) {
  .testimonial-nav.left {
    margin-right: 270px;
  }

  .testimonial-nav.right {
    margin-left: 270px;
  }

  .testimonial-slider {
    max-width: 80%;
  }

  .testimonial-card {
    max-width: 80%;
  }

  .testimonial-nav {
    font-size: 24px;
    display: flex;
  }
}

/*___________________________________________________For Model Box__________________________________________________*/

.staffing-enquiry-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fafbff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  width: 90%;
  max-width: 500px;
  margin: 0;
}

.staffing-enquiry-header {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.staffing-enquiry-form {
  display: flex;
  flex-direction: column;
}

.staffing-enquiry-close-btn {
  position: absolute !important;
  top: -16px;
  right: -16px;
}

.staffing-enquiry-close-btnX {
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  color: white !important;
  font-weight: bold;
  font-size: 16px !important;
}

.staffing-enquiry-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.staffing-buttoniconcommon {
  margin-right: 3px;
  font-size: 16px !important;
  margin-top: -2px;
}

.phone-input {
  width: 100% !important;
  height: 55px !important;
  font-size: 16px !important;
  background: #fafbff !important;
}

.phone-button {
  background: #fafbff;
  border-right: none;
}

.phone-button-error {
  background: white;
  border: 1px solid red;
  border-right: none;
}

.phone-input-spacing {
  margin-top: 8px;
  margin-bottom: 5px;
}

.phone-input-dropdown {
  font-size: 16px;
  max-height: 200px;
  overflow-y: auto;
  text-align: left;
}

.phone-input-dropdown::-webkit-scrollbar {
  width: 0;
}

.phone-input-dropdown {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0;
}

html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/*____________________________________________Responsive for Modal Box____________________________________________*/

@media screen and (min-width: 1025px) {
  .staffing-enquiry-content {
    width: 450px;
    max-width: 500px;
    margin: 0 auto;
  }

  .staffing-enquiry-header {
    font-size: 1.5rem;
  }

  .staffing-enquiry-close-btn {
    top: -16px;
    right: -16px;
  }

  .staffing-enquiry-close-btnX {
    font-size: 16px !important;
    padding: 4px;
  }

  .staffing-buttoniconcommon {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .staffing-enquiry-content {
    width: 80%;
    margin: 0 auto;
  }

  .staffing-enquiry-header {
    font-size: 1.4rem;
  }

  .staffing-enquiry-close-btn {
    top: -15px;
    right: -15px;
  }

  .staffing-enquiry-close-btnX {
    font-size: 15px !important;
    padding: 3px;
  }

  .staffing-buttoniconcommon {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 600px) {
  .staffing-enquiry-content {
    width: 90%;
    padding: 15px;
    margin: 0 auto;
  }

  .staffing-enquiry-header {
    font-size: 1.2rem;
  }

  .staffing-enquiry-close-btn {
    top: -15px;
    right: -15px;
  }

  .staffing-enquiry-close-btnX {
    font-size: 14px !important;
    padding: 2px;
  }

  .staffing-buttoniconcommon {
    font-size: 14px !important;
  }
}

/* _______________________________________________Staffing table__________________________________________ */

.staffing_table_outerContainer {
  width: 100%;
  background-color: white;
}

.staffing_table_innerContainer {
  max-width: 1140px;
  margin: 0 auto;
  margin-top: -10px;
  padding: 5px;
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

.staffing_table_table {
  width: 100%;
  border-collapse: collapse;
}

.staffing_table_table th,
.staffing_table_table td {
  border: 1px solid #2d2d2d;
  text-align: left;
  padding: 13px;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  background-color: #ecf5fc;
}

.staffing_table_table th {
  font-weight: bold;
}

@media (max-width: 769px) {
  .staffing_table_innerContainer h3 {
    font-size: 1.5rem !important;
  }

  .staffing_table_table th,
  tr,
  td {
    font-size: 1rem !important;
  }
}

@media (max-width: 520px) {
  .staffing_table_table {
    font-size: 1.2rem !important;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }

  .staffing_table_table th,
  .staffing_table_table td {
    padding: 10px;
    font-size: 12px;
  }
}
@media (max-width: 517px) {
  .staffing_table_table {
    font-size: 1.2rem !important;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }

  .staffing_table_table th,
  .staffing_table_table td {
    padding: 10px;
    font-size: 10px;
  }
}
@media (max-width: 485px) {
  .staffing_table_table {
    font-size: 1rem !important;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }

  .staffing_table_table th,
  .staffing_table_table td {
    padding: 10px;
    font-size: 8px;
  }
}
@media (max-width: 424px) {
  .staffing_table_table {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    font-size: 1rem !important;
  }

  .staffing_table_table th,
  .staffing_table_table td {
    padding: 8px;
    font-size: 10px;
  }
}

@media (max-width: 406px) {
  .staffing_table_table {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    font-size: 1rem !important;
  }

  .staffing_table_table th,
  .staffing_table_table td {
    padding: 6px;
    font-size: 9px;
  }
}

@media (max-width: 398px) {
  .staffing_table_table {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    font-size: 0.9rem !important;
  }

  .staffing_table_table th,
  .staffing_table_table td {
    padding: 8px;
    font-size: 0.9rem !important;
  }
}

@media (max-width: 384px) {
  .staffing_table_table {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }

  .staffing_table_table th,
  .staffing_table_table td {
    padding: 8px;
    font-size: 0.7rem !important;
  }
}

@media (max-width: 320px) {
  .staffing_table_table {
    width: 90%;
    max-width: 90%;
    overflow-x: auto;
  }

  .staffing_table_table th,
  .staffing_table_table td {
    padding: 3px;
    font-size: 7px;
  }
}
