.whatsapp-button-container {
  position: fixed;
  bottom: 70px;
  right: 15px;
  z-index: 1000;
}

.whatsapp-icon {
  font-size: 40px !important;
  color: #25d366;
  transition: transform 0.3s ease-in-out;
}

.whatsapp-icon:hover {
  transform: scale(1.1);
}
