.Normal-left-use-case-outer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.Normal-left-use-case-container {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 1140px;
  margin: 0 auto;
  padding: 20px;
}

.Normal-left-use-case-image {
  width: 50%;
  padding: 20px;
}

.Normal-left-use-case-image img {
  width: 100%;
  height: auto;
  border-radius: 30px;
}

.Normal-left-use-case-content {
  width: 50%;
  padding: 20px;
}

.Normal-left-use-case-content h2 {
  font-size: 1.7rem;
  margin-top: 0px;
  text-align: left;
  font-family: "Roboto", sans-serif;
}

.Normal-left-use-case-content p {
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: left;
  text-align: justify;
  font-family: "Roboto", sans-serif;
}

.Normal-right-use-case-outer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.Normal-right-use-case-container {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 1140px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.Normal-right-use-case-image {
  width: 50%;
  padding: 20px;
}

.Normal-right-use-case-image img {
  width: 100%;
  height: auto;
  border-radius: 30px;
}

.Normal-right-case-content {
  width: 50%;
  padding: 20px;
}

.Normal-right-case-content h2 {
  font-size: 1.7rem;
  margin-top: -3px;
  text-align: left;
  font-family: "Roboto", sans-serif;
}

.Normal-right-case-content p {
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: left;
  text-align: justify;
  font-family: "Roboto", sans-serif;
}

.horizontal-line {
  border: none;
  border-top: 0.5px solid #ccc;
  width: 100%;
}

@media (max-width: 768px) {
  .Normal-left-use-case-container {
    flex-direction: column;
    padding: 0;
  }

  .Normal-left-use-case-image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 0;
  }

  .Normal-left-use-case-image img {
    max-width: 90%;
    padding-bottom: 20px;
  }

  .Normal-left-use-case-content {
    width: 100%;
    text-align: center;
    order: -1;
    padding: 0;
  }

  .Normal-left-use-case-content h2 {
    font-size: 1.5rem;
    text-align: left;
    padding-left: 10px;
    padding-top: 40px;
  }

  .Normal-left-use-case-content p {
    font-size: 1rem;
    padding: 10px;
  }

  /*_____________________________________________Responsive for Right Side________________________________________*/

  .Normal-right-use-case-container {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
    padding: 0;
  }

  .Normal-right-use-case-image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 0;
  }

  .Normal-right-use-case-image img {
    max-width: 90%;
    padding-bottom: 20px;
  }

  .Normal-right-case-content {
    width: 100%;
    text-align: center;
    order: -1;
    padding: 0;
  }

  .Normal-right-case-content h2 {
    font-size: 1.5rem;
    text-align: left;
    padding-left: 10px;
    padding-top: 40px;
  }

  .Normal-right-case-content p {
    font-size: 1rem;
    padding: 10px;
  }
}
