.Land-staffing-page-outer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fafbff;
}

.Land-staffing-page-container {
  display: flex;
  justify-content: space-between;
  width: 1140px;
  padding: 10px 30px !important;
}

.Land-left-section {
  width: 40%;
  padding: 10px 0;
  text-align: left;
}

.Land-left-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: justify;
  font-family: "Roboto", sans-serif;
}

.Land-left-section p {
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  text-align: justify;
}

.Land-right-section {
  width: 55%;
  position: relative;
}

.Land-sticky-container {
  top: 20px;
}

.Land-section-content {
  display: none;
  padding: 20px 0;
  margin-bottom: 20px;
  transition: opacity 0.5s ease-in-out;
}

.Land-section-content.active {
  display: block;
}

.Land-icon {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.Land-staff-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 30px;
  text-decoration: none;
}

.Land-staff-button:hover {
  background-color: #0056b3;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/* Responsive Design */
@media (max-width: 900px) {
  .Land-staffing-page-outer {
    height: auto;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .Land-staffing-page-container {
    width: 90%;
    flex-direction: column;
  }

  .Land-left-section,
  .Land-right-section {
    width: 100%;
  }
  .Land-section-content img {
    width: 90%;
  }

  .Land-left-section h2 {
    font-size: 1.5rem;
  }

  .Land-left-section p {
    font-size: 1rem;
  }

  .Land-staff-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
