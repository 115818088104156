.contact-outer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding-bottom: 20px;
  margin-top: 10px;
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 1140px !important;
  padding-top: 30px;
  padding-left: 25px;
}

.contact-form-box {
  padding: 30px;
  width: 75%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.submit-button {
  background-color: #007bff !important;
  margin-top: 20px !important;
  font-family: "Roboto", sans-serif;
  font-weight: 700 !important;
}

.submit-button:hover {
  background-color: #0056b3 !important;
}

.contact-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-img {
  width: 100%;
  height: auto;
}

.contact-bold-text {
  font-weight: 700 !important;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
}

/*________________________________________________Contact Info_____________________________________________*/

.contactInfoOuter {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fafbff;
  padding: 50px 0;
}

.contactInfoContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1140px;
  gap: 20px;
}

.contactCard {
  background: white;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin: 20px;
  opacity: 0;
  transform: translateY(80px);
  transition: opacity 0.3s ease-out, transform 0.4s ease-out;
}

.contactCard.fade-in-card {
  opacity: 1;
  transform: translateY(0);
}

.contactCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.contactCardContent {
  text-align: center;
  padding: 30px;
  font-family: "Roboto", sans-serif;
}

.contactIcon {
  color: #007bff;
  font-size: 2rem !important;
  padding: 20px;
  border-radius: 50%;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.5s ease-out, transform 0.6s ease-out;
}

.contactIcon.fade-in-icon {
  opacity: 1;
  transform: translateY(0);
}

.contactIcon svg {
  font-size: 2rem;
}

.contactCard:hover .contactIcon {
  color: #0056b3;
}

.contactLabel {
  font-weight: 700;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  color: #333;
  margin-bottom: 10px;
}

.contactLink {
  display: inline-block;
  font-weight: 500;
  font-size: 1rem;
  color: #666;
  text-decoration: none;
  transition: color 0.2s;
}

.contactLink:hover {
  color: #283593;
  text-decoration: underline;
}

/*______________________________________________Responsive Styles_________________________________________*/

@media only screen and (max-width: 900px) {
  .contact-form-box {
    margin-left: 90px;
    width: 65%;
  }
}

@media only screen and (max-width: 768px) {
  .contact-container {
    align-items: center;
    padding-left: 0;
  }
  .contact-form-box {
    width: 80%;
    margin: 0 auto;
  }
  .contact-image-container {
    width: 60%;
    margin: 20px auto;
  }

  .contact-image img {
    width: 80%;
  }

  .contactInfoContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contactCard {
    width: 120%;
    margin: 20px 0;
  }

  .contactCardContent {
    padding: 20px;
  }

  .contactIcon {
    font-size: 1.8rem !important;
  }

  .contactLabel {
    font-size: 1.1rem;
  }

  .contactLink {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 480px) {
  .contact-form-box {
    padding: 15px;
  }
  .submit-button {
    font-size: 16px;
  }
  .contactCard {
    width: 100%;
  }

  .contactCardContent {
    padding: 15px;
  }

  .contactIcon {
    font-size: 1.5rem !important;
  }

  .contactLabel {
    font-size: 1rem;
  }

  .contactLink {
    font-size: 0.85rem;
  }
}
