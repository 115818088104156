.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.scroll-to-top.visible {
  opacity: 1;
}

.scroll-to-top:hover {
  background-color: #28a745;
}

.scroll-icon {
  color: white;
  font-size: 24px;
}
