.header-snack-top-bar-background-color {
  background-color: #226d9d;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 101;
}

.header-snack-top-bar-width-conatiner {
  max-width: 1140px;
  margin: auto;
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 10px;
}

.social-media-icons-top-snack-bar {
  font-size: 14px !important;
  color: white !important;
  cursor: pointer;
  margin-top: 2px;
}

.social-media-icons-top-snack-bar-mail {
  font-size: 14px !important;
  color: white !important;
  cursor: pointer;
}

.mail-info-container {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  margin-top: -1px;
}

.mail-info-text {
  margin-top: 0px;
  margin-left: 4px;
  font-weight: 500;
  color: white;
  font-size: 12.5px;
}

@media (max-width: 768px) {
  .header-snack-top-bar-width-container {
    justify-content: space-between;
    padding-right: 5px;
  }

  .social-media-icons-top-snack-bar,
  .social-media-icons-top-snack-bar-mail {
    font-size: 12px !important;
  }

  .mail-info-text {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .header-snack-top-bar-width-container {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 0;
  }

  .mail-info-container {
    margin-left: 0;
  }
}
