.mode-grid-outer {
  width: 100%;
  background-color: #fafbff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.model-grid-container {
  width: 1140px;
  text-align: center;
  margin: auto;
}

.services-heading {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
  font-family: "Roboto", sans-serif;
}

.hex-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 600px;
  height: 600px;
  margin: 0 auto;
}

.hex-wrapper {
  position: absolute;
}

.hex-wrapper a {
  text-decoration: none;
  color: inherit;
}

.central-hex {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hex {
  width: 220px;
  height: 155.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  clip-path: polygon(
    25% 6.7%,
    75% 6.7%,
    100% 50%,
    75% 93.3%,
    25% 93.3%,
    0% 50%
  );
  color: white;
  font-family: "Times New Roman", Times, serif;
  text-align: center;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.hex-text {
  padding: 8px 0;
  font-size: 22px;
}

.hex .fa-icon {
  margin-top: 10px;
}

.hex:hover {
  background-color: rgba(255, 255, 255, 0.7);
  transform: scale(1.1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
}

.hex:hover .fa-icon {
  font-size: 1.5em;
  transform: scale(0.9);
}

.central-hex .hex {
  background-color: #d19e47;
}

.hex-top-left .hex {
  background-color: #3a4f99;
}

.hex-top .hex {
  background-color: darkblue;
}

.hex-top-right .hex {
  background-color: black;
}

.hex-bottom-left .hex {
  background-color: rgba(50, 2, 2, 0.813);
}

.hex-bottom-right .hex {
  background-color: purple;
}

.hex-bottom .hex {
  background-color: rgb(10, 46, 10);
}

.hex-top-left {
  top: 50%;
  left: 1%;
}

.hex-top {
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.hex-top-right {
  top: 24%;
  right: 1%;
}

.hex-bottom-left {
  bottom: 50%;
  left: 1%;
}

.hex-bottom-right {
  bottom: 23%;
  right: 1%;
}

.hex-bottom {
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 620px) {
  .services-heading {
    margin-bottom: -80px;
  }

  .hex-grid {
    width: 590px;
    height: 590px;
    transform: translateX(-1%);
  }

  .hex {
    width: 170px;
    height: 98px;
  }

  .hex-text {
    font-size: 17px;
  }

  .hex-top-left {
    top: 33%;
    left: 10%;
  }

  .hex-top {
    top: 24%;
    left: 50%;
    transform: translateX(-50%);
  }

  .hex-top-right {
    top: 32%;
    right: 10%;
  }

  .hex-bottom-left {
    bottom: 32%;
    left: 10%;
  }

  .hex-bottom-right {
    bottom: 32%;
    right: 10%;
  }

  .hex-bottom {
    bottom: 24%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 585px) {
  .services-heading {
    margin-bottom: -80px;
  }

  .hex-grid {
    width: 100%;
    height: 585px;
    transform: translateX(-1%);
  }

  .hex {
    width: 170px;
    height: 98px;
  }

  .hex-text {
    font-size: 17px;
  }

  .hex-top-left {
    top: 33%;
    left: 10%;
  }

  .hex-top {
    top: 24%;
    left: 50%;
    transform: translateX(-50%);
  }

  .hex-top-right {
    top: 32%;
    right: 10%;
  }

  .hex-bottom-left {
    bottom: 32%;
    left: 10%;
  }

  .hex-bottom-right {
    bottom: 32%;
    right: 10%;
  }

  .hex-bottom {
    bottom: 24%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 572px) {
  .services-heading {
    font-size: 24px;
    margin-bottom: -10px;
  }

  .hex-grid {
    width: 400px;
    height: 400px;
    margin: 0 auto;
  }

  .hex {
    width: 130px;
    height: 75px;
    position: relative;
  }

  .hex-text {
    display: none;
    font-size: 9px;
  }

  .hex .fa-icon {
    font-size: 1.5em;
  }

  .hex:hover .fa-icon {
    font-size: 1.2em;
  }

  .hex:hover::after {
    content: attr(data-text);
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 12px;
    z-index: 1;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  .hex::after {
    visibility: hidden;
    opacity: 0;
  }

  .hex:hover .hex-text {
    display: block;
    visibility: visible;
  }

  .hex-top-left {
    top: 30%;
    left: 5%;
  }

  .hex-top {
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
  }

  .hex-top-right {
    top: 30%;
    right: 5%;
  }

  .hex-bottom-left {
    bottom: 30%;
    left: 5%;
  }

  .hex-bottom-right {
    bottom: 30%;
    right: 5%;
  }

  .hex-bottom {
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 397px) {
  .hex-grid {
    width: 300px;
    height: 300px;
  }

  .hex {
    width: 110px;
    height: 70px;
  }

  .hex-text {
    font-size: 5px;
  }

  .hex .fa-icon {
    font-size: 10px;
  }

  .hex-top-left {
    top: 27%;
    left: 0%;
  }

  .hex-top {
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
  }

  .hex-top-right {
    top: 27%;
    right: 0%;
  }

  .hex-bottom-left {
    bottom: 26%;
    left: 0%;
  }

  .hex-bottom-right {
    bottom: 26%;
    right: 0%;
  }

  .hex-bottom {
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
  }

  .hex:hover .hex-text {
    font-size: 8px;
  }
}
