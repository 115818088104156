.success-stories-container {
  background-color: rgb(231, 241, 248);
  text-align: center;
  width: 100%;
  position: relative;
}

.success-story-container {
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
  padding: 5px;
  overflow: hidden;
}

.success-story-heading {
  font-size: 32px;
  font-family: "Roboto", sans-serif;
  color: black;
  margin-bottom: 40px;
}

.success-story-title {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin-top: 0px;
}

.success-story-description {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  text-align: left;
  line-height: 1.4;
  text-align: justify;
}

.success-stories {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  gap: 30px;
}

.success-story {
  background: #ffffff;
  border-radius: 15px;
  padding: 20px;
  width: 250px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 180px;
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
  margin-left: 15px;
}

.custom-arrow {
  background: transparent;
  border: none;
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
}

.left-arrow {
  left: -6px;
}

.right-arrow {
  right: -3px;
}

.custom-dot-list-style li {
  margin: 0 2px;
}

.custom-dot-list-style {
  margin-top: 20px;
}

.success-stories {
  display: flex;
  justify-content: center;
  gap: 20px;
  overflow: hidden;
}

@media screen and (min-width: 1024px) and (max-width: 1115px) {
  .success-stories {
    justify-content: center;
    gap: 20px;
  }

  .success-story {
    width: 225px;
    margin-left: 27px;
  }

  .custom-arrow {
    background: transparent;
    border: none;
    position: absolute;
    top: 38%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
  }

  .left-arrow {
    left: 0;
  }

  .right-arrow {
    right: 3px;
  }
}

@media screen and (max-width: 766px) {
  .success-stories {
    display: flex;
    justify-content: center;
  }

  .success-story {
    max-width: 250px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .success-story-heading {
    font-size: 28px;
  }

  .success-story-title {
    font-size: 20px;
  }

  .success-story-description {
    font-size: 15px;
  }
  .custom-arrow {
    background: transparent;
    border: none;
    position: absolute;
    top: 38%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
  }

  .left-arrow {
    left: 10px;
  }

  .right-arrow {
    right: 5px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 767px) {
  .success-stories-container {
    display: flex;
    justify-content: center;
    gap: -440px;
  }

  .success-story {
    margin: 0 auto;
    margin-bottom: 40px;
    width: 250px;
  }

  .custom-arrow {
    background: transparent;
    border: none;
    position: absolute;
    top: 38%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    overflow: visible;
  }

  .left-arrow {
    left: 10px;
  }

  .right-arrow {
    right: 5px;
  }
}
