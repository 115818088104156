.footer {
  background-color: #f4f5ff;
  width: 100%;
}

.footer-container {
  color: #0e1734;
  max-width: 1140px;
  margin: auto;
  padding: 2px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.footer-container2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: auto;
  gap: -70px;
  width: 100%;
}

.footer-column {
  flex: 1;
  margin: 2px;
  min-width: 100px;
  text-align: left;
}

.products {
  margin-left: 50px;
  width: 22%;
  padding: 0;
}
.products h3 {
  margin-left: 150px;
}
.products-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 8px;
  margin-left: 150px;
  margin-right: 280px;
}
.products-list a {
  color: #0e1734;
  transition: color 0.3s ease;
  text-decoration: none;
  position: relative;
}

.products-list a:hover {
  color: #673de6;
}

.products-list a::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background: #673de6;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.products-list a:hover::after {
  transform: scaleX(1);
}

.contact {
  margin-left: -200px;
}

.contact h3 {
  margin-bottom: 8px;
  text-align: center;
  margin-left: 10px;
}

.contact-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: -19px;
}

.footer-column.contact {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.footer-column.contact .contact-column {
  flex: 1;
  min-width: 200px;
}

.footer-column.contact ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column.contact ul li {
  margin: 3px 0;
}

.contact-column li {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  margin-right: 250px;
}

.contact-icon {
  font-size: 20px;
  color: #0e1734;
}

.contact-icon-text {
  font-size: 17px;
  margin-left: 7px;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  padding-top: 2px;
}

.social-icons a {
  color: #0e1734;
  margin: 0 5px;
  font-size: 20px;
}

.footer-bottom {
  text-align: center;
  margin: 0;
  color: #0e1734;
  font-size: 11px;
  width: 100%;
  box-sizing: border-box;
}

/*_________________________________________________Responsive Code________________________________________*/

@media (max-width: 995px) {
  .footer-container2 {
    align-items: center;
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact h3 {
    margin-bottom: 0px;
    text-align: left;
    margin-left: -250px;
  }
  .footer_heading {
    align-items: center;
    display: flex;
    text-align: center;
  }
  .products {
    margin-top: -125px;
    margin-left: 30px;
  }
  .products-list {
    justify-items: left;
  }

  .products h3 {
    justify-content: start;
    margin-left: 150px;
  }
  .contact-info-grid {
    display: block;
    text-align: center;
  }
  .social-icons {
    margin-top: -38px;
  }

  .contact-column {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
}

@media (max-width: 979px) {
  .footer-container2 {
    align-items: center;
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact h3 {
    margin-bottom: 0px;
    text-align: left;
    margin-left: -250px;
  }
  .footer_heading {
    align-items: center;
    display: flex;
    text-align: center;
  }
  .products {
    margin-top: -125px;
    margin-left: 20px;
  }
  .products-list {
    justify-items: left;
    margin-right: 160px;
  }

  .products h3 {
    justify-content: start;
    margin-left: 150px;
  }
  .contact-info-grid {
    display: block;
    text-align: center;
  }
  .social-icons {
    margin-top: -38px;
  }

  .contact-column {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
}

@media (max-width: 829px) {
  .footer-container2 {
    align-items: center;
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact h3 {
    margin-bottom: 0px;
    text-align: left;
    margin-left: -250px;
  }
  .footer_heading {
    align-items: center;
    display: flex;
    text-align: center;
  }
  .products {
    margin-top: -125px;
    margin-left: -60px;
  }
  .products-list {
    justify-items: left;
    margin-right: 160px;
  }

  .products h3 {
    justify-content: start;
    margin-left: 150px;
  }
  .contact-info-grid {
    display: block;
    text-align: center;
  }
  .social-icons {
    margin-top: -38px;
  }

  .contact-column {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .footer-container2 {
    flex-direction: column;
    align-items: center;
  }

  .products {
    margin-left: 60px;
  }

  .products h3 {
    gap: 15px;
    margin-right: 10px;
    font-size: 18px;
    margin-top: 150px;
    margin-left: -20px;
  }

  .products-list {
    grid-template-columns: 1fr;
    margin-left: -90px;
    margin-right: 30px;
    gap: 4px;
    justify-items: center;
    line-height: 1.5;
    align-items: center;
    text-align: center;
  }

  .contact {
    padding-left: 20px;
    margin-left: -30px;
    width: 100%;
    text-align: center;
    display: block;
  }
  .contact h3 {
    margin-bottom: 1px;
    text-align: center;
    margin-left: -80px;
  }

  .contact-info-grid {
    display: block;
    gap: 10px;
    margin-left: 70px;
    text-align: center;
    line-height: 1.5;
    margin-top: -10px;
  }
  .social-icons {
    margin-left: 160px;
  }
}

@media (max-width: 590px) {
  .footer-container2 {
    align-items: center;
    flex-direction: column;
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact h3 {
    margin-bottom: 1px;
    text-align: center;
    margin-left: -80px;
  }

  .products h3 {
    gap: 15px;
    margin-right: 10px;
    font-size: 18px;
    margin-top: 90px;
    margin-left: -10px;
  }
  .products-list {
    justify-items: center;
    line-height: 1.5;
    align-items: center;
    text-align: center;
    margin-left: -90px;
  }

  .contact-info-grid {
    display: block;
    text-align: center;
    line-height: 1.5;
    margin-top: 30px;
  }

  .social-icons {
    margin-left: 160px;
  }

  .contact-column {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }

  .contact ul li {
    margin: 5px 0;
    text-align: center;
  }
}

@media (max-width: 750px) {
  .contact {
    display: block;
    text-align: center;
    padding-left: 20px;
  }

  .products h3 {
    gap: 15px;
    margin-right: 10px;
    font-size: 18px;
    margin-top: 150px;
    margin-left: -20px;
  }

  .products-list {
    justify-items: center;
    line-height: 1.5;
    align-items: center;
    text-align: center;
    margin-left: -70px;
    line-height: 1.5;
  }

  .contact h3 {
    margin-bottom: 2px;
    text-align: center;
  }

  .contact-info-grid {
    display: block;
    text-align: center;
    line-height: 1.5;
    margin-top: -10px;
  }

  .contact-column {
    width: 100%;
    margin-bottom: 10px;
  }

  .contact ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .contact ul li {
    margin: 5px 0;
  }
}

@media (max-width: 480px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .footer-container2 {
    align-items: center;
  }

  .products h3 {
    gap: 15px;
    margin-right: 10px;
    font-size: 18px;
    margin-top: 110px;
    margin-left: -45px;
  }

  .products-list {
    justify-items: center;
    line-height: 1.5;
    align-items: center;
    text-align: center;
    margin-left: -80px;
    line-height: 1.5;
  }
  .contact-info-grid {
    display: block;
    grid-template-columns: 1fr;
    gap: 0;
    text-align: center;
    margin-top: -10px;
  }

  .contact-column {
    width: 100%;
  }
}

@media (max-width: 518px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .footer-container2 {
    align-items: center;
  }

  .products {
    display: block;
    text-align: center;
    padding-left: 10px;
  }

  .products h3 {
    display: flex;
    text-align: center;
    margin-top: 40px;
    margin-left: -50px;
  }

  .products-list {
    justify-items: center;
    margin-left: -90px;
  }

  .contact-info-grid {
    display: block;
    grid-template-columns: 1fr;
    gap: 0;
    text-align: center;
    line-height: 1.5;
    margin-top: -10px;
  }

  .contact-column {
    width: 100%;
  }
}

@media (max-width: 558px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .footer-container2 {
    align-items: center;
  }

  .products {
    display: block;
    text-align: center;
    padding-left: 10px;
  }

  .products h3 {
    display: flex;
    text-align: center;
    margin-top: 150px;
    margin-left: -40px;
  }

  .products-list {
    justify-items: center;
    margin-left: -80px;
  }

  .contact-info-grid {
    display: block;
    grid-template-columns: 1fr;
    gap: 0;
    text-align: center;
    line-height: 1.5;
    margin-top: -10px;
  }

  .contact-column {
    width: 100%;
  }
}

@media (max-width: 462px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .footer-container2 {
    align-items: center;
  }

  .products h3 {
    gap: 15px;
    margin-right: 10px;
    font-size: 18px;
    margin-top: 150px;
    margin-left: -40px;
  }

  .products-list {
    justify-items: center;
    line-height: 1.5;
    align-items: center;
    text-align: center;
    margin-left: -60px;
    line-height: 1.5;
  }

  .contact h3 {
    margin-left: -160px;
  }

  .social-icons {
    margin-left: 70px;
  }

  .contact-info-grid {
    display: block;
    grid-template-columns: 1fr;
    gap: 0;
    text-align: center;
    margin-top: -10px;
    margin-left: 5px;
  }

  .contact-column {
    width: 100%;
  }
}

@media (max-width: 395px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .footer-container2 {
    align-items: center;
  }

  .contact-column {
    width: 100%;
  }

  .products h3 {
    font-size: 15px;
  }

  .products-list {
    font-size: 14px;
  }

  .contact h3 {
    font-size: 15px;
    margin-left: -150px;
  }

  .social-icons {
    font-size: 10px;
  }

  .contact-icon-text {
    font-size: 14px;
  }
}

@media (max-width: 336px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .footer-container2 {
    align-items: center;
  }

  .contact-column {
    width: 100%;
  }

  .products h3 {
    font-size: 13px;
  }

  .products-list {
    font-size: 12px;
  }

  .contact h3 {
    font-size: 13px;
    margin-left: 10px;
  }

  .contact-icon-text {
    font-size: 12px;
  }
}
