.usecase-left-outer-div {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.usecase-left-inner-div {
  display: flex;
  width: 1140px;
}

.usecase-left-image-div {
  width: 50%;
}

.usecase-left-image {
  width: 70%;
  height: auto;
  padding: 10px;
  border-radius: 10px;
}

.usecase-left-content-div {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: -25px;
}

.usecase-left-problem-statement,
.usecase-left-solution,
.usecase-left-efficiency {
  text-align: justify;
  font-size: 1.2rem;
  padding: 10px;
  font-family: "Roboto", sans-serif;
}

.usecase-left-heading {
  font-weight: bold;
  font-size: 1.7rem;
  text-align: justify;
  padding: 10px;
  font-family: "Roboto", sans-serif;
}

/*__________________________________________________For Right____________________________________________________*/

.usecase-right-outer-div {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.usecase-right-inner-div {
  display: flex;
  width: 1140px;
}

.usecase-right-image-div {
  width: 50%;
}

.usecase-right-image {
  width: 70%;
  height: auto;
  padding: 10px;
  border-radius: 10px;
}

.usecase-right-content-div {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: -25px;
}

.usecase-right-problem-statement,
.usecase-right-solution,
.usecase-right-efficiency {
  text-align: justify;
  font-size: 1.2rem;
  padding: 10px;
  font-family: "Roboto", sans-serif;
}

.usecase-right-heading {
  font-weight: bold;
  font-size: 1.7rem;
  text-align: justify;
  padding: 10px;
  font-family: "Roboto", sans-serif;
}

.horizontal-line {
  border: none;
  border-top: 0.5px solid #ccc;
  width: 100%;
}

@media (max-width: 768px) {
  .usecase-left-inner-div {
    flex-direction: column;
  }

  .usecase-left-image-div,
  .usecase-left-content-div {
    width: 100%;
  }

  .usecase-left-content-div {
    order: -1 !important;
    padding-left: 0;
  }

  .usecase-left-heading {
    font-size: 1.5rem !important;
  }

  .usecase-left-problem-statement,
  .usecase-left-solution,
  .usecase-left-efficiency {
    font-size: 1rem !important;
  }

  /*__________________________________________Responsive For Right Section______________________________________*/

  .usecase-right-inner-div {
    flex-direction: column;
  }

  .usecase-right-image-div,
  .usecase-right-content-div {
    width: 100%;
  }

  .usecase-right-content-div {
    padding-left: 0;
  }

  .usecase-right-heading {
    font-size: 1.5rem !important;
  }

  .usecase-right-problem-statement,
  .usecase-right-solution,
  .usecase-right-efficiency {
    font-size: 1rem !important;
  }
}

@media (max-width: 480px) {
  .usecase-left-heading {
    font-size: 1.5rem !important;
  }

  .usecase-left-problem-statement,
  .usecase-left-solution,
  .usecase-left-efficiency {
    font-size: 1rem !important;
  }

  .usecase-right-heading {
    font-size: 1.5rem !important;
  }

  .usecase-right-problem-statement,
  .usecase-right-solution,
  .usecase-right-efficiency {
    font-size: 1rem !important;
  }
}
