.background_container {
  width: 100%;
  background-color: white;
}

.company-logo-container {
  max-width: 1140px;
  margin: auto;
  position: relative;
}

.company-logo-heading {
  color: #0e1734;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
}

.logos-slide-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.logos-slides {
  display: flex;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.logos-slides img {
  max-width: 100px;
  max-height: 100px;
  padding: 10px;
  margin-right: 100px;
  margin-bottom: 30px;
  background: none;
  box-shadow: none;
  border: none;
}

/*__________________________________________Responsive styles_____________________________________________*/

@media screen and (max-width: 1024px) {
  .company-logo-heading {
    font-size: 35px;
  }
  .company-logo-heading {
    font-size: 25px;
  }

  .logos-slides img {
    max-width: 1140px;
    max-height: 100px;
    margin-right: 80px;
  }
}

@media screen and (max-width: 768px) {
  .company-logo-heading {
    font-size: 30px;
  }

  .logos-slides img {
    max-width: 1140px;
    max-height: 80px;
    margin-right: 50px;
  }

  .background_container,
  .company-logo-container {
    width: 100%;
  }

  .logos-slides {
    animation: scroll 15s linear infinite;
  }
}

@media screen and (max-width: 480px) {
  .company-logo-heading {
    font-size: 20px;
  }

  .logos-slides img {
    max-width: 60px;
    max-height: 60px;
    margin-right: 30px;
  }

  .background_container,
  .company-logo-container {
    width: 100%;
  }
}
