.FullStack-background-container {
  width: 100vw;
  background-color: rgb(246, 248, 252);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.Fullstack_section2 {
  max-width: 1140px;
  margin: 0 auto;
  text-align: left;
  color: black;
  padding: 20px;
}

.Fullstack_section2 h2 {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
}

.Fullstack_section2 p {
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  margin: 10px 0;
  line-height: 1.7;
  text-align: justify;
}

@media (max-width: 768px) {
  .Fullstack_section2 p {
    font-size: 1rem;
  }

  .Fullstack_section2 h2 {
    font-size: 1.5rem;
  }
}
