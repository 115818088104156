.carousel_inside {
  width: 1140px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  padding: 15px;
  margin-top: 40px;
}

.carousel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  background-color: white;
  margin-top: -90px;
}

.slide {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-img {
  width: 100%;
  height: 500px;
  object-fit: fill;
  object-position: center;
}

/*________________________________________Carousel Control Arrow Previous ________________________________*/

.carousel .control-arrow.control-prev {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-top: 250px;
  background-color: rgba(116, 121, 143, 0.7) !important;
  opacity: 1 !important;
  border: 1px solid #6a6f73;
  box-shadow: 0 2px 4px rgba(162, 160, 160, 0.08),
    0 4px 12px rgba(0, 0, 0, 0.08);
  margin-left: 8px;
}

/*__________________________________________Carousel Control Arrow Next___________________________________*/

.carousel .control-arrow.control-next {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-top: 250px;
  background-color: rgba(116, 121, 143, 0.7) !important;
  opacity: 1 !important;
  border: 1px solid #6a6f73;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
  margin-right: 8px;
}

/*_______________________________________________Carousel Control Dots____________________________________*/

.carousel .control-dots {
  width: 80%;
  position: absolute;
  bottom: 30px;
  left: 12%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

/*____________________________________________Carousel Control Dots Dot____________________________________*/

.carousel .control-dots .dot {
  width: 10px;
  height: 10px;
  background: white !important;
  border: 2px solid white !important;
  margin: 0 5px;
}

.text-overlay {
  position: absolute;
  left: 5%;
  bottom: 25%;
  color: #fff;
  text-align: left;
  padding: 10px 20px;
  font-size: 24px;
  width: 490px;
  word-wrap: break-word;
  opacity: 0;
  animation-duration: 3s;
  font-family: "Roboto", sans-serif;
}

.text-overlay.fade-in {
  animation: fadeInOverlay 2s forwards;
}

/* Keyframes for animations */
@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*_______________________________________________Responsive Styles________________________________________*/

@media (max-width: 1168px) {
  .text-overlay {
    width: 60%;
    left: 5%;
    bottom: 20%;
    font-size: 24px;
    width: 550px;
    word-wrap: break-word;
  }
  .carousel_inside {
    margin-top: 42px;
    width: 100%;
  }
  .carousel .control-dots {
    width: 40%;
    bottom: 40px;
  }

  .carousel .control-dots .dot {
    width: 10px;
    height: 10px;
    border: 3px solid white !important;
    left: 20%;
  }
}

@media (max-width: 980px) {
  .text-overlay {
    width: 60%;
    left: 5%;
    bottom: 17%;
    font-size: 24px;
    width: 465px;
    word-wrap: break-word;
  }
  .carousel_inside {
    margin-top: -50px;
    width: 100%;
  }
  .carousel .control-dots {
    width: 40%;
    bottom: 40px;
    left: 13%;
  }
  .carousel {
    margin-top: 2px;
  }
  .carousel .control-dots .dot {
    width: 10px;
    height: 10px;
    border: 3px solid white !important;
    left: 19%;
  }
}

@media (max-width: 768px) {
  .carousel_inside {
    margin-top: 40px;
    width: 100%;
  }

  .carousel {
    margin-top: -90px;
  }
  .carousel-img {
    height: 400px;
  }
  .carousel .control-arrow.control-prev,
  .carousel .control-arrow.control-next {
    height: 40px;
    width: 40px;
    margin-top: 190px;
    border-radius: 50%;
  }

  .text-overlay {
    width: 80%;
    left: 5%;
    bottom: 29%;
    font-size: 18px;
    width: 400px;
    word-wrap: break-word;
  }
  .carousel .control-dots {
    width: 70%;
    bottom: 70px;
    left: 15%;
  }

  .carousel .control-dots .dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
  }
  .carousel .carousel-status {
    right: 50px;
  }
}

@media (max-width: 580px) {
  .text-overlay {
    width: 90%;
    left: 5%;
    bottom: 25%;
    font-size: 15px;
    width: 300px;
    word-wrap: break-word;
  }
  .carousel {
    margin-top: -40px;
  }

  .carousel-img {
    height: 300px;
  }

  .carousel_inside {
    margin-top: -15px;
    width: 100%;
  }

  .carousel .control-arrow.control-prev,
  .carousel .control-arrow.control-next {
    height: 40px;
    width: 40px;
    margin-top: 200px;
  }

  .carousel .control-dots {
    width: 90%;
    bottom: 50px;
    left: 16%;
  }
  .carousel .control-dots .dot {
    width: 8px;
    height: 8px;
    margin: 0 3px;
  }
  .carousel .carousel-status {
    right: 10px;
  }
}

@media (max-width: 394px) {
  .text-overlay {
    width: 90%;
    left: 7%;
    bottom: 28%;
    font-size: 13px;
  }
  .carousel {
    margin-top: -40px;
  }

  .carousel_inside {
    margin-top: -15px;
    width: 100%;
    padding: 0;
  }

  .carousel .control-arrow.control-prev,
  .carousel .control-arrow.control-next {
    height: 40px;
    width: 40px;
    margin-top: 150px;
  }

  .carousel .control-dots {
    width: 60%;
    bottom: 50px;
    margin-left: 25px;
  }
  .carousel .control-dots .dot {
    width: 8px;
    height: 8px;
    margin: 0 3px;
  }
  .carousel .carousel-status {
    right: 10px;
  }
}
