.enquiry-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fafbff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  width: 90%;
  max-width: 500px;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.enquiry-header {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.enquiry-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
}

.enquiry-close-btn {
  position: absolute !important;
  top: -16px;
  right: -16px;
}

.enquiry-close-btnX {
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  color: white !important;
  font-weight: bold;
  font-size: 16px !important;
}

.enquiry-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-top: auto;
  padding-top: 20px;
}

.buttoniconcommon {
  margin-right: 3px;
  font-size: 16px !important;
  margin-top: -2px;
}

/*____________________________________________Responsive for Modal Box____________________________________________*/

/* For large screens (above 1024px) */
@media screen and (min-width: 1025px) {
  .enquiry-content {
    width: 450px;
    max-width: 500px;
    margin: 0 auto;
  }

  .enquiry-header {
    font-size: 1.5rem;
  }

  .enquiry-close-btn {
    top: -16px;
    right: -16px;
  }

  .enquiry-close-btnX {
    font-size: 16px !important;
    padding: 4px;
  }

  .buttoniconcommon {
    font-size: 16px !important;
  }
}

/* For medium screens (601px to 1024px) */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .enquiry-content {
    width: 80%;
    margin: 0 auto;
  }

  .enquiry-header {
    font-size: 1.4rem;
  }

  .enquiry-close-btn {
    top: -15px;
    right: -15px;
  }

  .enquiry-close-btnX {
    font-size: 15px !important;
    padding: 3px;
  }

  .buttoniconcommon {
    font-size: 15px !important;
  }
}

/* For small screens (up to 600px) */
@media screen and (max-width: 600px) {
  .enquiry-content {
    width: 90%;
    padding: 15px;
    margin: 0 auto;
  }

  .enquiry-header {
    font-size: 1.2rem;
  }

  .enquiry-close-btn {
    top: -15px;
    right: -15px;
  }

  .enquiry-close-btnX {
    font-size: 14px !important;
    padding: 2px;
  }

  .buttoniconcommon {
    font-size: 14px !important;
  }
}
