.process-progress-circle-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(141, 141, 141, 0.491);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.process-progress-circle-background-color {
  background-color: rgb(255, 255, 255);
  height: 130px;
  width: 320px;
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 0px 10px gray;
  border-radius: 10px;
  padding: 25px;
  padding-top: 20px;
}

.text-conataienalignment-progrees-bar {
  color: black;
  padding: 10px;
  margin-top: 20px;
}

.progress-bar-alignment {
  margin-top: 10px;
}
