.CadAutomation-background-container {
  width: 100vw;
  background-color: rgb(246, 248, 252);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.CadAutomation_section2 {
  max-width: 1140px;
  margin: 0 auto;
  text-align: left;
  color: black;
  padding: 20px;
}

.CadAutomation_section2 h2 {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
}

.CadAutomation_section2 p {
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  margin: 10px 0;
  line-height: 1.7;
  text-align: justify;
}

@media (max-width: 768px) {
  .CadAutomation_section2 p {
    font-size: 1rem;
  }

  .CadAutomation_section2 h2 {
    font-size: 1.5rem;
  }
}

/* _______________________________________services_____________________________________ */

.autoservices-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.autoservices-content {
  max-width: 1140px;
  text-align: justify;
  font-size: 1.2rem;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
}

.autoservices-logo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-left: -30px;
  width: 100%;
  max-width: 1140px;
}

.autoservices-logo img {
  width: 170px;
  margin: 0 10px;
}

/* Responsive styles */
@media (max-width: 1029px) {
  .autoservices-content {
    font-size: 1.2rem;
  }

  .autoservices-logo-container {
    margin-top: 30px;
  }
}

@media (max-width: 1024px) {
  .autoservices-content {
    font-size: 1.2rem;
  }

  .autoservices-logo-container {
    margin-top: -10px;
  }
}

@media (max-width: 768px) {
  .autoservices-content {
    font-size: 1.2rem;
  }

  .autoservices-logo-container {
    justify-content: center;
    margin-top: -10px;
  }
  .autoservices-logo img {
    width: 120px;
  }
}

@media (max-width: 625px) {
  .autoservices-content {
    font-size: 1rem;
  }

  .autoservices-logo-container {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -10px;
  }

  .autoservices-logo img {
    width: 100px;
    margin: 5px;
  }
}

@media (max-width: 480px) {
  .autoservices-content {
    font-size: 1rem;
  }

  .autoservices-logo-container {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -15px;
  }

  .autoservices-logo img {
    width: 80px;
    margin: 2px;
  }
}
@media (max-width: 402px) {
  .autoservices-content {
    font-size: 1rem;
  }

  .autoservices-logo-container {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -15px;
  }

  .autoservices-logo img {
    width: 70px;
    margin: 1px;
    margin-left: 20px;
  }
}
